<template>
    <Select
            v-model="selected_site"
            :items="sites"
            :label="$t('navbar.SelectSite.choose_site')"
            background="cell"
            itemText="display_name"
            @change="changeSite()"
    />
</template>

<script>
    export default {
        name: "SelectSite",
        components: {
            Select: () => import('@/components/fields/Select.vue')
        },
        computed: {
            sites(){
                return this.$store.getters['auth/available_sites']
            },
            selected_site: {
                get(){
                    return this.$store.getters['auth/selected_site']
                },
                set(val){
                    this.$store.commit('auth/CHANGE_SELECTED_SITE_VAL', val)
                }
            },
        },
        methods: {
            changeSite(){
                this.$store.commit('CHANGE_APPLICATION_READY_VAL', false)

                let site = 'https://' + this.$store.getters['auth/sites'].find(e => e.id === this.selected_site.id).backend + '/api/'

                this.$store.commit('config/CHANGE_BACKEND_URL_VAL', site)
                this.$store.dispatch('auth/loginState')
            }
        }
    }
</script>
